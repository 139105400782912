<template>
  <div class="VisitorsPage">
    <img class="logo" src="@/assets/img/logo.png" alt="" />
    <img class="visitorsPage_btn" @click="goLogin" src="@/assets/img/visitorsPage_btn.png" alt="" />

    <div class="jasa">
      <img src="@/assets/img/jasa.png" alt="">
      <img src="@/assets/img/afpi.png" alt="">
    </div>
    <div class="advertising" v-show="advertisingShow">
      <img class="advertising_close" src="@/assets/img/advertising_close.png" @click="advertisingShow = false" />
      <div class="advertising_content">
        <img class="advertising_content_logo" src="@/assets/img/home1_yinliu_logo.png" />
        <div class="advertising_content_name">
          <span>Halo Yak - Pinjaman Online OJK</span>
          <span>App Store</span>
        </div>
        <a class="advertising_download" href="https://apps.apple.com/id/app/halo-yak-pinjam-duit/id6742484604?l=id">
          Download Aplikasi
        </a>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data () {
    return {
      advertisingShow: true
    }
  },
  created () {
  },
  methods: {
    goLogin () {
      this.$router.push({
        path: '/login'
      })
    }
  }

}
</script>

<style scoped lang='less'>
.VisitorsPage {
  position: relative;
  background: url('~@/assets/img/visitorsPage_bg.png') no-repeat;
  background-size: 100% 100%;
  height: 100vh;

  .logo {
    position: absolute;
    top: 30px;
    left: 50px;
    width: 120px;
    height: 120px;
  }

  .visitorsPage_btn {
    position: absolute;
    // 垂直左右居中
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100px;
  }

  .jasa {
    position: absolute;
    // 垂直左右居中
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;

    img {
      height: 70px;
    }
  }

  .advertising {
    height: 90px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    .advertising_close {
      position: absolute;
      top: 0;
      width: 30px;
      height: 30px;
    }

    .advertising_content {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.7);

      .advertising_content_logo {
        width: 70px;
        height: 70px;
        border-radius: 10px;
        margin-left: 20px;
        margin-right: 10px;
      }

      .advertising_content_name {
        display: flex;
        flex-direction: column;
        color: #fff;

        span:first-child {
          font-size: 22px;
          font-weight: 600;
          margin-bottom: 6px;
        }

        span:last-child {
          font-size: 20px;
        }
      }

      .advertising_download {
        padding: 0 20px;
        background: #58a9ca;
        font-size: 24px;
        border-radius: 40px;
        height: 60px;
        text-align: center;
        line-height: 60px;
        color: #fff;
        margin-left: auto;
        margin-right: 30px;
      }
    }

  }
}
</style>
